@mixin angular-material($theme) {
  $primary: map-get($theme, primary);
  $warn: map-get($theme, warn);
  $accent: map-get($theme, accent);

  .mat-drawer-container{
    z-index: 10 !important;
    overflow: initial !important; // je suis pas sur de ça... Fix un bug sur safari, ios
  }

  $sidenavWidth: 400px;
  $sidenavSmallWidth: 70px;

  mat-sidenav-container{
    mat-sidenav {
      transition: width 0.3s !important; // overide inline style from angular material
      width: $sidenavWidth - 45px; // moins large sur desktop qu'en mobile.
      &.small{
        width: $sidenavSmallWidth;
      }
      &.mobile{
        width: $sidenavWidth;
      }
    } 
    mat-sidenav-content{
      //transition: margin-left 0.3s !important; // overide inline style from angular material
      //margin-left: $sidenavWidth - 45px !important; // overide inline style from angular material, moins large sur desktop qu'en mobile.
      &.small{
        //margin-left: $sidenavSmallWidth !important; // overide inline style from angular material
      }
      &.mobile{
        margin-left: 0px !important; // overide inline style from angular material
      }
    }
    .mat-drawer-side{
      border-right: none;
      box-shadow: -2px 4px 5px 0px #000000;
    }
  }

  mat-nav-list{
    &.menu.menu{
      padding-top: 0px;
      mat-list-item.mat-list-item{
        .mat-list-item-content{
          padding: 10px 20px;
          .mat-list-text{
            padding-left: 16px;
          }
        }
        &.active-item{
          p{
            color: mat-color($accent);
          }
          mat-icon.greyColor{
            color: mat-color($accent);
          }
          .icon.greyColor{
            color: mat-color($accent);
          }
        }
      }
      &.mobile{
        mat-list-item.mat-list-item{
          .mat-list-item-content{
            .mat-list-text{
              padding-left: 30px;
            }
          }
        }
      }
    }
  }

  button{
    &.full{
      width: 100%;
    }
  }

  mat-slide-toggle{
    width: 100%;
    .mat-slide-toggle-content{
      width: 100%;
      span:last-of-type{
        white-space: normal;
        padding-left: 10px;
      }
    }
  }

  table.mat-table{
    width: 100%;
    thead{
      // TODO display: none;
    }
    th.mat-header-cell{
      // TODO display: none;
    }
    td.mat-cell{
      padding: 0;
    }
    &.alternate-bg{
      tr:not(.mat-header-row){
        &:nth-child(even){
          background: #EFEFEF;
        }
      }
    }
  }

  mat-list.paginated{
    width: 100%;
    height: 100%;
    background: var(--white);
    overflow: auto;

    div.tableContainer{
      &.desktop{
        height: 100%;
        display: block;
        overflow-y: auto;
        table.mat-table{
          &.noData{
            display: none;
          }

          thead {
            display: none;
          }
        }
      }
    }
    mat-paginator{
      &.noData{
        display: none;
      }
    }

    fe-article-item:last-child {
      padding-bottom: 10px;
    }

    .articlesContainer {
      display: flex;
      flex-direction: column;
    }
  }
  

  

  mat-paginator.mat-paginator{
    &.mobile{
      position: absolute;
      top: -500px;
    }
  }

  .cardFlexContainer{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .modalCard{
      width: 700px;
      padding: 20px;
      box-shadow: 0px 3px 20px 5px rgba(0, 0, 0, 0.2),
                  0px 1px 1px 0px rgba(0, 0, 0, 0.14),
                  0px 1px 3px 0px rgba(0, 0, 0, 0.12);
      &.noBoxShadow{
        box-shadow: none;
      }
      &.mobile{
        width: 100%;
      }
    }
  }
  .cardContainer{
    @extend .cardFlexContainer;
    display: block;
    &.desktop{
      overflow: auto;
    }
    .modalCard{
      margin: 0 auto;
    }
  }

  .hide {
    display: none;
  }
}
