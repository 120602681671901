@mixin colors($theme) {
  $primary: map-get($theme, primary);
  $warn: map-get($theme, warn);
  $accent: map-get($theme, accent);

  $paletteOptions: (050, 100, 200, 300, 400, 500, 600, 700, 800, 900);

  // --------------
  .primaryColor{
    color: mat-color($primary);
  }
  .primaryBgColor{
    background: mat-color($primary);
  }
  @each $paletteOption in $paletteOptions {
    .primaryColor_#{$paletteOption} {
      color: mat-color($primary, $paletteOption);
    }
    .primaryBgColor_#{$paletteOption} {
      background: mat-color($primary, $paletteOption);
    }
  }
  
  // --------------
  .warnColor{
    color: mat-color($warn);
  }
  .warnBgColor{
    background: mat-color($warn);
  }
  @each $paletteOption in $paletteOptions {
    .warnColor_#{$paletteOption} {
      color: mat-color($warn, $paletteOption);
    }
    .warnBgColor_#{$paletteOption} {
      background: mat-color($warn, $paletteOption);
    }
  }

  // --------------
  .accentColor{
    color: mat-color($accent);
  }
  .accentBgColor{
    background: mat-color($accent);
  }
  @each $paletteOption in $paletteOptions {
    .accentColor_#{$paletteOption} {
      color: mat-color($accent, $paletteOption);
    }
    .accentBgColor_#{$paletteOption} {
      background: mat-color($accent, $paletteOption);
    }
  }

  // --------------
  
  .greyColor{
    color: $grey;
  }
  .greyBgColor{
    background: $grey;
  }
  .greyBorderBottom{
    border-bottom: solid 1px rgba(0, 0, 0, 0.12);
  }
  .greyBorderTop{
    border-top: solid 1px rgba(0, 0, 0, 0.12);
  }
  .greyLightColor{
    color: $greyLight;
  }
  .greyLightBgColor{
    background: $greyLight;
  }
  .redBgColor{
    background :#b19a96;
  }
  .redLightBgColor{
    background :#C20015;
  }


  // --------------

  .whiteColor {
    color: $white;
  }

  .whiteBgColor {
    background: $white;
  }

  .whiteBorderBottom {
    border-bottom: solid 1px $white;
  }
}
