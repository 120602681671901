@font-face {
	font-family: "firsteco-icons";
	src: url("./assets/fonts/firsteco-icons/firsteco-icons.eot?4d404f7de85f22dbd19c89470ba2a68f?#iefix") format("embedded-opentype"),
	url("./assets/fonts/firsteco-icons/firsteco-icons.woff2?4d404f7de85f22dbd19c89470ba2a68f") format("woff2"),
	url("./assets/fonts/firsteco-icons/firsteco-icons.woff?4d404f7de85f22dbd19c89470ba2a68f") format("woff");
}

.icon {
	line-height: 1;
	font-size: 32px;
}

.icon:before {
	font-family: firsteco-icons !important;
	font-style: normal;
	font-weight: normal !important;
	vertical-align: top;
}

.icon-first-eco-arrow:before {
	content: "\f101";
}
.icon-first-eco-search:before {
	content: "\f102";
}

.buildingIcon {
	width: 21px;
	height: 21px;
	margin: auto 8px auto 0;
}

.batimentIcon {
	width: 30px;
	margin: auto 0;
}

.websiteIcon, .markerIcon {
	pointer-events: initial!important;
	width: 21px;
	height: 21px;
}

.webMarkerIconContainer {
	pointer-events: initial!important;
	margin: auto 0 auto 5px;
	padding: 0;
	outline: none;
	background: transparent;
	min-width: 21px;
}
