@mixin forms($theme) {
  $primary: map-get($theme, primary);
  $warn: map-get($theme, warn);
  $accent: map-get($theme, accent);

  .mat-form-field-label{
    padding-left: 10px;
  }
  .mat-form-field-subscript-wrapper{
    padding-left: 10px;
  }
  .mat-input-element.mat-input-element{
    padding-left: 8px;
  }
  .mat-error{
    text-align: right;
    &.customMatError{
      font-size: 75%;
    }
    &.withMarginTop{
      padding-top: 5px;
    }
  }

  .input {
    &:focus {
      outline: 0;
      border: 1px solid mat-color($primary);
    }
  }


  .mat-checkbox {
    &.circle {

      .mat-checkbox-inner-container{
        padding: 10px;

        .mat-checkbox-frame, .mat-checkbox-background{
          border-radius: 50%;
        }
      }

      &.icon{
        .mat-checkbox-label {
          display: flex;
          align-items: center;
        }
      }

    }
  }

  .countryCode{
    .mat-option-text{
      display: flex;
      align-items: center;
      .iti-flag{
        margin-right: 10px;
      }
    }
  }

  .mat-select{
    .mat-select-value-text{
      margin-left: 8px;
    }
  }

  ion-searchbar {
    &.rounded-searchbar {
      .searchbar-input {
        border-radius: 21px !important;
        opacity: 1!important;
      }
    }
  }
}
