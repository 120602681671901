@mixin list($theme) {
  $primary: map-get($theme, primary);
  $warn: map-get($theme, warn);
  $accent: map-get($theme, accent);

  mat-list.mat-list {

    padding-top: 0;

    &.alternate-bg {
      mat-list-item.mat-list-item:nth-child(even) {
        background: #EFEFEF;
      }
      mat-list-item.mat-list-item:nth-child(odd) {
        background: #FFFFFF;
      }
    }

    article-toggle-favorite{
      &.big{
        margin-top: -100px;
      }
    }

    mat-checkbox.mat-checkbox{
      padding: 0px 16px 0px 0px;

      &.big{
        // margin-top: -100px;
      }
    }

    mat-list-item.mat-list-item{
      color: #273C4E;
      /*font-size: 14px;*/
      line-height: 20px;
      transition: height 0.2s ease-in-out;
      height: auto;
      margin-top: 10px;
      margin-bottom: 10px;

      &.active {
        cursor: pointer;
      }

      &.small{
        div.listTitle{
          height: 40px;
        }
      }

      &.big{
        height: auto;
      }

      &.all {
        height: auto;
      }

      div.textContainer{
        flex: 1;

        div.listDate{
          font-size: 12px;
          line-height: 16px;
        }
        
        div.listTitle{
          overflow: hidden;
          text-overflow: ellipsis;
          /* max-height: 40px; */
          font-weight: bold;
        }
      }

      &.my-searches-box {
        .mat-list-item-content {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          padding: 0 5px;

          p {
            flex: 1;
            font-weight: bold;
          }
        }
      }
      
      &.sending-history-list-item {
        .mat-list-item-content {
          justify-content: space-between;
        }
      }

      &.cdk-drag-placeholder {
        color: #FFFFFF !important;
        background: mat-color($primary);
        box-sizing: border-box;
        box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
        0 8px 10px 1px rgba(0, 0, 0, 0.14),
        0 3px 14px 2px rgba(0, 0, 0, 0.12);
      }
    }
  }

  /* Drag'n'Drop List */
  .cdk-drag-preview {
    display: none;
  }

/*   .cdk-drag-placeholder {
    color: #FFFFFF !important;
    background: mat-color($primary);
    box-sizing: border-box;
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
      0 8px 10px 1px rgba(0, 0, 0, 0.14),
      0 3px 14px 2px rgba(0, 0, 0, 0.12);
  } */

  /* Override the height of mat-list-items for the my-searches page */
  .mat-list-base .mat-list-item,
  .mat-list-base .mat-list-option {
    &.heightAuto{
      height: auto !important;
    }
  }
}
