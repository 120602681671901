@mixin dialogs($theme) {
  $primary: map-get($theme, primary);
  $warn: map-get($theme, warn);
  $accent: map-get($theme, accent);

  .desktopDialog {
    .modal-wrapper {
      //width: 70%;

      width: 90%;
      height: 80%;

      max-width: 1400px;
    }

    ion-backdrop {
      --backdrop-opacity: 0.33;
    }
  }

  .smallDesktopDialog {
    .modal-wrapper {
      max-width: 700px;
    }
    ion-backdrop {
      --backdrop-opacity: 0.33;
    }
  }

  .templateDesktopDialog {
    .ion-page {
      width: 1300px;
      height: 750px;
    }
  }

  .chartDesktopDialog {
    .modal-wrapper {
      width: 1300px;
      height: 500px;
    }
    ion-backdrop {
      --backdrop-opacity: 0.33;
    }
  }

  .modalClotureArticleConfirm {
    .modal-wrapper {
      //width: 70%;

      width: 20%;
      height: 35%;

      max-width: 1400px;
    }

    ion-backdrop {
      --backdrop-opacity: 0.33;
    }
  }

  .modalAddFolder {
    .modal-wrapper {
      height: 25%;
      width: 20%;
    }
  }

  .addFavoriteDialog {
    .modal-wrapper {
      //width: 70%;

      width: 500px;
      height: 330px;

      //max-width: 1400px;
    }

    ion-backdrop {
      --backdrop-opacity: 0.33;
    }
  }

  .addMonitoredArticleDialog {
    .modal-wrapper {
      width: 600px;
      height: 570px;
    }


    ion-backdrop {
      --backdrop-opacity: 0.33;
    }
  }


  .addLeadDialog {
    .modal-wrapper {
      width: 550px;
      height: 485px;
    }


    ion-backdrop {
      --backdrop-opacity: 0.33;
    }
  }

  .editMonitoredArticleCommentDialog {
    .modal-wrapper {
      width: 600px;
      height: 500px;
    }


    ion-backdrop {
      --backdrop-opacity: 0.33;
    }


  }

  .manageSearchesModal {
    .modal-wrapper {
      width: 65vw;
      height: 75vh;
      border-radius: 8px;
    }


    ion-backdrop {
      --backdrop-opacity: 0.33;
    }
  }

  .searchModal {
    //.modal-wrapper {
    //  width: 100%;
    //  height: 90%;
    --width: 100%;
    --height: 100%;
    margin-top: 70px;
      //border-radius: 8px;
    //}


    //ion-backdrop {
    //  --backdrop-opacity: 0.33;
    //}
  }

  .ficheEModal {
    .modal-wrapper {
      width: 90%;
      height: 80%;

      max-width: 1400px;
    }
  }

  @media (max-width: 900px) {
    .ficheEModal {
      .modal-wrapper {
        width: 100%;
        height: 100%;
      }
    }
  }

}
