@mixin common($theme) {
  $primary: map-get($theme, primary);
  $warn: map-get($theme, warn);
  $accent: map-get($theme, accent);

  .outlined {
    font-family: 'Material Icons Outlined' !important;
  }

  .italic{
    font-style: italic;
  }

  mat-sidenav-container{
    height: 100%;
    mat-sidenav{
      width: 200px;
    }
  }

  ion-toolbar{
    &.greyBorder {
      border-bottom: solid 1px #D8D8D8;
    }
  }

  mat-toolbar{
    &.smallAccent{
      height: 30px;
      span{
        font-size: 0.8em;
        font-weight: normal;
      }
    }
  }

  .agm-info-window-content{
    .buttons{
      margin-top: 5px;
      text-align: right;
    }
  }
  .mat-tooltip
  {
    font-size: 12px!important;
  }

  [data-title]:hover:after {
    opacity: 1;
    transition: all 0.1s ease 0.5s;
    visibility: visible;
  }
  [data-title]:after {
    //content: attr(data-title);
    //background-color: #00FF00;
    //color: #111;
    //font-size: 150%;
    //position: absolute;
    //padding: 1px 5px 2px 5px;
    //bottom: -1.6em;
    //left: 100%;
    //white-space: nowrap;
    //box-shadow: 1px 1px 3px #222222;
    //opacity: 0;
    //border: 1px solid #111111;
    //z-index: 99999;
    //visibility: hidden;

    content: attr(data-title);
    color: #fff;
    border-radius: 4px;
    margin: 14px;
    max-width: 250px;
    padding-left: 8px;
    padding-right: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    background: rgba(97,97,97,.9);
    font-family: Poppins,"Helvetica Neue",sans-serif;
    font-size: 10px;
    padding-top: 6px;
    padding-bottom: 6px;
    z-index: 99999;
    position: absolute;
    bottom: -5em;
    left: 50%;
    visibility: hidden;
  }
  [data-title] {
    position: relative;
    z-index: 500;
  }
}
