/* http://mcg.mbitson.com/ */

$mat-primary: (
  50 : #e5e8ea,
  100 : #bec7cc,
  200 : #92a1aa,
  300 : #667b87,
  400 : #465f6e,
  500 : #273C4E,
  600 : #213d4d,
  700 : #1b3443,
  800 : #162c3a,
  900 : #0d1e29,
  A100 : #68baff,
  A200 : #35a3ff,
  A400 : #028cff,
  A700 : #007ee7,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #ffffff,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #ffffff,
    A700 : #ffffff,
  )
);

$mat-accent: (
  50 : #fde8ea,
  100 : #f9c5cb,
  200 : #f59ea8,
  300 : #f17785,
  400 : #ee5a6a,
  500 : #C20015,
  600 : #e93749,
  700 : #e52f40,
  800 : #e22737,
  900 : #dd1a27,
  A100 : #ffffff,
  A200 : #ffdddf,
  A400 : #ffaaaf,
  A700 : #ff9097,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);
