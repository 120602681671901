.margAuto {
	margin: auto;
}

.padLeft10 {
	padding-left: 10px;
}

.padLeft20 {
	padding-left: 20px;
}

.dispIB {
	display: inline-block;
}

.curPoint {
	cursor: pointer;
}
