/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import '@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

@import "theme/_custom-themes.scss";
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;500&display=swap');

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  mat-sidenav-container .mat-drawer-side {
    box-shadow: 0px 4px 5px 0px #000000 !important;
  }
}

* {
  font-family: 'Inter', sans-serif;
  letter-spacing: normal;
}

:root {
  --main-blue: #003F51;
  --light-blue: #475A66;
  --white-blue: #E6F5FA;
  //--tag-background: #E0E8EB;
  //--tag-background: #daf1f9;
  --tag-background: #cfe0e6;
  --secondary-tag-color: #333333;
  --grey-blue: #E8EFF2;
  --light-black: #262626;   //#181818
  --red: #BF0214;
  --dark-red: #891E0B;
  --white-red: #FEF8F6;
  --green: #17921D;
  --grey-button: #F8F9FA;
  --dark-green: #085E1A;
  --white: #FFFFFF;
  --black: #000000;
  --backgound: #f9fbfd;
  --white-grey: #F3F5F6;
  --white-grey-hover: #e4e6e7;
  --light-grey: #DFE0E0;
  --grey: #A3A8A9;
  --dark-grey: #666E70;
  --grey-article: #EDEDED;
  --blue-hover: #EDF2F4;
  --backdrop: #455E807F;
  --hover-searchlist: #EBEBEB;
}

.lightBlackC {
  color: var(--light-black) !important;
}

.darkGreyC {
  color: var(--dark-grey) !important;
}

.greyC {
  color: var(--grey) !important;
}

.greenC {
  color: var(--green) !important;
}

.mat-mdc-checkbox-touch-target {
  width: 100%!important;
  height: 100%!important;
}

mat-checkbox .mdc-checkbox .mdc-checkbox__background {
  border-radius: 4px;
}

.mdc-checkbox .mdc-checkbox__native-control {
  width: 100%!important;
  height: 100%!important;
}

.svgBlue {
  filter: invert(18%) sepia(26%) saturate(2257%) hue-rotate(153deg) brightness(102%) contrast(104%);
}

.svgRed {
  filter: invert(18%) sepia(57%) saturate(6652%) hue-rotate(347deg) brightness(72%) contrast(112%);
}

.svgLightBlack {
  filter: invert(9%) sepia(0%) saturate(0%) hue-rotate(200deg) brightness(100%) contrast(86%);
}

.svgDarkGrey {
  filter: invert(41%) sepia(8%) saturate(342%) hue-rotate(145deg) brightness(99%) contrast(86%);
}

.svgGrey {
  filter: invert(80%) sepia(6%) saturate(165%) hue-rotate(142deg) brightness(84%) contrast(85%);
}

.svgWhite {
  filter: invert(91%) sepia(100%) saturate(0%) hue-rotate(23deg) brightness(103%) contrast(101%);
}

.svgGreen {
  filter: invert(42%) sepia(15%) saturate(4956%) hue-rotate(88deg) brightness(91%) contrast(84%);
}

// Pour choisir d'autres filtres : https://codepen.io/sosuke/pen/Pjoqqp

.smallBlueButton {
  padding: 2px 6px 2px 4px;
  color: white;
  background: var(--main-blue);
  border: none;
  border-radius: 4px;
  display: flex;

  span {
    font-size: 12px;
    margin: auto;
  }

  mat-icon {
    color: white !important;
    height: 20px;
    width: 20px;
    font-size: 20px;
    margin-right: 2px;
  }
}

.blueBoldText {
  color: var(--main-blue);
  font-weight: bold;
}

::ng-deep mat-sidenav {
  background: var(--backgound);
}

.searchBar {
  background: #F8F9FA;
  color: #A3A8A9;
  border: 1px solid #DFE0E0;
  border-radius: 50px;
  display: flex;
  padding: 0 5px;

  input {
    border: none;
    outline: none;
    background: transparent;
    width: 100%;
    color: var(--main-blue);
  }

  mat-icon {
    color: #A3A8A9;
  }
}

.blueLeftBar {
  border-left: 2px solid var(--main-blue);
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #F8F9FA;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #A3A8A9;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #666E70;
}

* {
  -webkit-user-select: auto!important;
  -moz-user-select: auto!important;
  -ms-user-select: auto!important;
  user-select: auto!important;
}

.selectable {
    -webkit-user-select: auto!important;
    -moz-user-select: auto!important;
    -ms-user-select: auto!important;
    user-select: auto!important;
}

.txtCenter {
  text-align: center !important;
}

.leads {
  display: flex;
  border:1px solid rgba(0,0,0,.2);
  margin: 2px;
  padding: 3px;
  border-radius: 15px;
}
.leadsDisabled {
  background-color: rgb(0, 0, 0, .2);
}
.leadsDisabled:active {
  pointer-events:none;
}
.leadsDisabled:hover {

}

.mat-mdc-menu-content {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

::ng-deep .feMatMenu {
  max-width: 300px !important;
  background: red;
}

.mat-menu-item{
  line-height:35px!important;
  height:35px!important;
  display: flex !important;
}

.mat-mdc-menu-panel {
  max-width: none !important;
}

.highlightItem {
  font-weight: bold!important;
  color: #8b0000;
  font-style: italic;
}

.alertLoginInfos div {
  max-width: 630px!important;
}

.validateButton {
  border: 1px solid!important;
  color: white!important;
  background-color: #273C4E!important;
  margin-right: 170px!important;
}
.cancelButton {
  //border: 1px solid!important;
  //background-color: #DDDDDD!important;
}

.default-snackbar, .confirm-snackbar, .danger-snackbar {
  background: var(--white);
  border: 1px solid #f1f1f1;
  border-radius: 0;

  &.mat-mdc-snack-bar-container .mdc-snackbar__surface {
    background: var(--white);
    border-radius: 0;
  }
}

.feOverflowBtn {
  overflow: hidden;

  button {
    width: 100%;

    span {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      text-wrap: nowrap;
    }
  }
}

.commonContainer {
  padding: 8px;
  border: 1px solid var(--grey);
  border-radius: 4px;
  margin: 8px;
  background: var(--white);
  display: flex;
  flex-direction: column;

  .commonContainerTitle {
    font-weight: bold;
    font-size: 16px;
    color: var(--black);
  }

  .commonContainerSubTitle {
    color: var(--dark-grey);
    font-size: 14px;
    margin-top: 4px;
  }
}

.fe-divider, .fe-divider-thin {
  display: flex;
  margin: 8px 0;
}

.fe-divider {
  border-bottom: 2px solid var(--light-grey);
}

.fe-divider-thin {
  border-bottom: 1px solid var(--light-grey);
}

.fe-divider-without-m {
  border-bottom: 2px solid var(--light-grey);
}

.fe-vert-divider {
  margin: 8px;
  border-left: 2px solid var(--light-grey);
}

.mdc-snackbar {
  border: none;
}

.default-snackbar.mat-mdc-snack-bar-container .mdc-snackbar__surface {
  border-left: 4px solid var(--light-black);
}

.confirm-snackbar.mat-mdc-snack-bar-container .mdc-snackbar__surface {
  border-left: 4px solid var(--green);
}

.danger-snackbar.mat-mdc-snack-bar-container .mdc-snackbar__surface {
  border-left: 4px solid var(--red);
}

.feSnackbarDefaultProgressBar {
  .mdc-linear-progress__bar-inner {
    border-color: var(--light-black);
  }
}

.feSnackbarConfirmProgressBar {
  .mdc-linear-progress__bar-inner {
    border-color: var(--green);
  }
}

.feSnackbarDangerProgressBar {
  .mdc-linear-progress__bar-inner {
    border-color: var(--red);
  }
}

// MODAL PART START

.feModal {
  overflow: hidden;

  .feModalHeader {
    padding: 8px 16px;
    border-bottom: 2px solid var(--white-grey);
    display: flex;

    .feModalTitle {
      text-align: left;
      font-size: 20px;
      font-weight: bold;
      color: var(--light-black);
      margin: auto 0;
      flex: 1;
    }

    .feTextLeft {
      text-align: left;
    }

    .feModalCloseButton {
      display: flex;

      & > span {
        margin: auto 10px auto 30px;
        font-size: 16px;
        font-weight: bold;
        cursor: pointer;
      }

      & > mat-icon {
        width: fit-content;
        height: fit-content;
        background: var(--white-grey);
        border-radius: 20px;
        text-align: left;
        padding: 4px;
        color: var(--main-blue);
        cursor: pointer;

        &:hover {
          background: var(--white-grey-hover);
        }
      }
    }
  }

  .feModalContent {
    padding: 16px;
    display: flex;
    flex-direction: column;
  }

  .feModalFooter {
    padding: 16px;
    border-top: 2px solid var(--white-grey);

    .getACopyContainer {
      margin-right: auto;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;
    }
  }
}

// MODAL PART END


// FONT PART START

.bold {
  font-weight: bold;
}

.littleText, .littleTextBold {
  font-size: 12px;
  font-family: Inter, sans-serif;
}

.moderateText, .moderateTextBold {
  font-size: 14px;
  font-family: Inter, sans-serif;
}

.bigText, .bigTextBold, .moderateTitle, .moderateTitleBold {
  font-size: 16px;
  font-family: Inter, sans-serif;
}

.veryBigText, .veryBigTextBold, .bigTitle, .bigTitleBold {
  font-size: 18px;
  font-family: Inter, sans-serif;
}

.veryBigTitle, .veryBigTitleBold {
  font-size: 20px;
  font-family: Inter, sans-serif;
  line-height: 24px;
}

.littleTextBold, .moderateTextBold, .bigTextBold, .moderateTitleBold,.veryBigTextBold, .bigTitleBold, .veryBigTitleBold {
  font-weight: bold;
}

.mainTitle {
  font-family: Poppins, sans-serif;
  font-size: 24px;
  font-weight: bold;
}

// FONT PART END


// CHECKBOX PART START

mat-checkbox {

  .mdc-checkbox {
    padding: 0;

    & .mdc-checkbox__background {
      top: initial;
      left: initial;
    }

    & .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background,
    .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background,
    .mdc-checkbox__native-control[data-indeterminate=true]:enabled~.mdc-checkbox__background {
      background: var(--main-blue) !important;
      border-color: var(--main-blue) !important;
    }

    &.mdc-checkbox .mdc-checkbox__native-control:focus:checked~.mdc-checkbox__ripple {
      background: transparent !important;
    }

    .mat-ripple {
      display: none;
    }
  }
}

// CHECKBOX PART END


// LOADER PART START

.feLoader {
  margin: auto;
  width: fit-content;
}

.customLoader {
  .fe-loader-container {
    padding: 20px;
  }
}

// LOADER PART END


// SEARCHBAR STYLE START

.feSearchBar {
  padding: 4px 8px;
  background: var(--grey-button);
  border-radius: 50px;
  display: flex;
  border: 1px solid var(--light-grey);

  &.thinSearchBar {
    padding: 4px 8px;
  }

  mat-icon {
    margin: auto 0;
  }

  fe-icon {
    margin-right: 4px;
  }

  input {
    border: none;
    background: transparent;
    outline: none;
    flex: 1;
  }
}

.cleanSearchBarIcon {
  cursor: pointer;
}

::ng-deep .mat-pseudo-checkbox{
  display: none !important;
}

.displayNone {
  display: none !important;
}

// SEARCHBAR PART END


// FAVORITE PART START

.feFavoriteItemLabel {
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex: 1;
  margin: auto;
  cursor: pointer;
  overflow: hidden;
}

.feFavoriteItemCount {
  display: flex;
  background: var(--light-grey);
  border-radius: 40px;
  height: 28px;
  width: 28px;

  span {
    margin: auto;
  }
}

// FAVORITE PART END


// MAIL PART START

.shadowBloc {
  padding: 6px 12px;
  background: white;
  border-radius: 4px;
  box-shadow: 0px 2px 8px 0px rgba(69, 94, 128, 0.18);
  border: 1px solid var(--light-grey);

  &.newsletterIem {
    width: fit-content;
    display: flex;
    flex-direction: column;
    gap: 8px;
    text-align: center;
    font-weight: bold;
    padding: 12px;
    cursor: pointer;
    border: 2px solid #ededed;

    &:hover {
      background: #E7E7E7;
    }

    &.selected {
      border: 2px solid #484848;
      background: #E7E7E7;
    }

    img {
      width: 280px;
    }
  }

  .shadowBlocTitle {
    display: flex;
    padding: 8px 0;
    gap: 12px;
  }

  .shadowBlocContent {
    display: flex;
    flex-direction: column;
    padding-bottom: 6px;

    &.unknowDestinataires {
      display: flex;
      flex-direction: column;
      gap: 8px;
      padding: 4px 0;

      .unknowDestinataire {
        padding: 16px;
        display: flex;
        flex-direction: column;
        align-items: center;
        border: var(--light-grey) 1px solid;
        box-shadow: 0px 2px 8px 0px rgba(69, 94, 128, 0.18);

        .unknowDestinataireText {
          width: 100%;
          display: flex;
          align-items: center;

          fe-input {
            min-width: 250px;
            margin-right: 40px;
          }

          mat-icon {
            margin-right: 0;
            cursor: pointer;
          }
        }

        .dangerColor {
          color: var(--dark-red);
          width: 100%;
          margin-left: 4px;
        }
      }
    }

    .searchDestinataireBloc {
      display: flex;
      gap: 12px;
      margin: 8px 0 20px;

      fe-button {
        display: flex;
      }
    }

    .destinatairesContainer {
      display: flex;
      flex-wrap: wrap;

      .destinataireAdded {
        display: flex;
        align-items: center;
        background: var(--grey-blue);
        border-radius: 25px;
        padding: 8px 4px;
        height: fit-content;

        ::ng-deep .mat-mdc-chip-action-label {
          display: flex;
          align-items: center;
          gap: 4px;
        }

        mat-icon {
          margin: 0;
          opacity: 1;
          color: var(--black);

          &.mat-mdc-chip-remove {
            font-size: 20px;
            font-weight: bold;
            height: 20px;
          }
        }

        .closeIcon {
          margin-left: 4px;
          cursor: pointer;
        }
      }
    }

    .mailArticleItem {
      display: flex;
      flex-direction: column;
      padding: 16px 8px;
      border-bottom: 1px solid var(--grey);
      gap: 8px;

      .mailArticleItemHeader {
        display: flex;
        gap: 8px;
        align-items: center;

        span {
          flex: 1;
        }

        .fe-icon {
          width: 24px;
          height: 24px;
          display: flex;
          align-items: center;
        }

        .mailArticleItemHeaderOptions {
          margin-left: auto;
          display: flex;
          gap: 20px;

          .deleteIcon {
            width: fit-content;
            height: fit-content;
            background: var(--white-grey);
            border-radius: 20px;
            text-align: left;
            padding: 4px;
            color: var(--main-blue);
            cursor: pointer;

            &:hover {
              background: var(--white-grey-hover);
            }
          }
        }
      }
    }
  }
}

// MAIL PART END


// GLOBAL STYLE START

.marginLeftAuto {
  margin-left: auto;
}

.marginRightAuto {
  margin-right: auto;
}

.fe-icon {
  display: flex;
  align-items: center;

  img {
    margin: auto;
  }
}

.mat-mdc-paginator .mat-mdc-form-field-infix {
  min-height: 20px;
  padding: 0 !important;
}

div.mat-mdc-select-panel {
  padding: 0 !important;
}

.mdc-list-item {
  padding: 4px !important;
  min-height: 40px !important;
}

.mat-mdc-option .mdc-list-item__primary-text {
  display: flex !important;
  color: initial !important;
  gap: 4px;
}

div.mat-mdc-autocomplete-panel {
  padding: 0 !important;
}

.searchContactAutoComp, .searchDestinataireAutoComp {
  min-width: 350px;
  margin-top: 8px;
  margin-left: -25px;
  background: var(--light-grey) !important;
  gap: 1px;
  display: flex;
  flex-direction: column;
}

.searchDestinataireAutoComp {
  min-width: 270px;
}

.contactOption {
  background: var(--white) !important;

  &:hover {
    background: var(--white-grey) !important;
  }

  .contactName {
    white-space: nowrap;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  mat-pseudo-checkbox {
    display: none;
  }

  ::ng-deep .mat-mdc-option .mdc-list-item__primary-text {
    color: initial !important;
  }
}

.mat-mdc-paginator-container {
  min-height: 20px !important;
}

.mat-mdc-paginator-range-actions {
  button {
    height: 24px !important;
    width: 24px !important;
    overflow: hidden;
  }
}

.selectedOption button {
  background: var(--grey-blue);
}

.feDotsMenu {
  width: 24px !important;
  height: 24px !important;
  display: flex;

  mat-icon {
    margin: 0;
  }
}

.actionsOnArticleMenu {

  button {
    min-height: 40px;
  }

  .mat-mdc-menu-item-text {
    display: flex;
    align-items: center;
    gap: 8px;
  }
}

.dropdownBtn {
  border: solid 1px #efefef;
  background-color: white;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #666E70;
  width: fit-content;
  padding: 4px 8px;
  height: fit-content;
  margin: auto;

  fe-icon {
    margin-right: 8px;
  }
}

@media (max-width: 1600px) {
  .dropdownBtn {
    padding: 2px 8px;
    font-size: 13px;
  }
}

.notSmallDesktop {
  display: flex;
}

@media (max-width: 1320px) {
  .dropdownBtn {
    fe-icon {
      margin-right: 0 !important;
    }
  }

  .notSmallDesktop {
    display: none;
  }
}

.flex1 {
  flex: 1;
}

#launcher {
  display: none;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #273c4e !important;
}

.checkAllArticlesBusinessSignals {
  margin-right: 10px;
}

.divActions {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 8%;
  margin-left: 17px;
  flex: 1;
}

.multipleActionBtn {
  border: solid 1px #273c4e;
  background-color: #273c4e;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 100%;
  color: white;
  width: fit-content;
  padding: 0 2px 0 5px;
  margin-right: 10px;
}

.multipleActionBtn2 {
  display: flex;
  align-items: center;
  padding: 2px 4px;
  border-radius: 4px;
  margin-left: 2px;
  background: transparent;

  .mdc-checkbox {
    display: flex;
  }

  &.active {
    background: var(--grey-blue);
  }
}

.menuArticleAction {
  margin-left: -22px;
  margin-top: 2px;
}

.noArticlesSelected{
  visibility: hidden;
}

.feTextArea {
  border: 1px solid var(--light-grey);
  border-radius: 4px;
  padding: 8px 16px;
  outline: none;
  resize: none;
  width: 100%;
  max-height: 150px;

  &::placeholder {
    color: var(--grey);
    opacity: 1; /* Firefox */
  }

  &::-ms-input-placeholder { /* Edge 12 -18 */
    color: var(--grey);
  }
}

.mat-mdc-paginator-page-size-label {
  display: none;
}

.marginAuto {
  margin: auto;
}

.matMenuLimitHeight {
  max-height: 500px;
}

@media (max-width: 1650px) {
  .dropdownBtn {
    //padding: 3px;
  }

  .fe-vert-divider {
    margin: 0px;
    border-left: 0px solid var(--light-grey);
  }
}

// GLOBAR STYLE END

//mat-sidenav-container{
//  height: 100%;
//  mat-sidenav{
//    width: 200px;
//  }
//}
