@use '@angular/material' as mat;

@use '@angular/material/theming';
@include mat.core();
@import "theme/_custom-palettes.scss";
@import "theme/colors.scss";
@import "theme/common.scss";
@import "theme/angular-material.scss";
@import "theme/firsteco-icons.scss";
@import "theme/stylisation.scss";
@import "theme/forms.scss";
@import "theme/list.scss";
@import "theme/buttons.scss";
@import "theme/dialogs.scss";

@mixin custom-components-theme($theme) {
  @include common($theme);
  @include angular-material($theme);
  @include colors($theme);
  @include forms($theme);
  @include list($theme);
  @include buttons($theme);
  @include dialogs($theme);
}

$grey: #7F7F7F;
$greyLight: #A3A3A3;
$white: #FFFFFF;

$primary: mat.define-palette($mat-primary, 500);
$accent: mat.define-palette($mat-accent, 500);
$warn: mat.define-palette($mat-primary, 500);
$customTheme: mat.define-palette($primary, 500);
////$customTheme: mat.define-light-theme($primary, $accent, $warn);
//$my-theme: mat.define-light-theme((
//        color: (
//                primary: $primary,
//                accent: $accent,
//                warm: $warn
//        ),
//        density: 0,
//));
//
//@include mat.core-theme($my-theme);
//@include mat.button-theme($my-theme);
//@include mat.angular-material-theme($customTheme);
@include custom-components-theme($customTheme);
