// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #273C4E;
  --ion-color-primary-rgb: 37,67,84;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255,255,255;
  --ion-color-primary-shade: #213b4a;
  --ion-color-primary-tint: #3b5665;

  /** secondary **/
  --ion-color-secondary: #C20015;
  --ion-color-secondary-rgb: 235,61,80;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255,255,255;
  --ion-color-secondary-shade: #cf3646;
  --ion-color-secondary-tint: #ed5062;

  /** tertiary **/
  --ion-color-tertiary: #7044ff;
  --ion-color-tertiary-rgb: 112, 68, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #633ce0;
  --ion-color-tertiary-tint: #7e57ff;

  /** success **/
  --ion-color-success: #10dc60;
  --ion-color-success-rgb: 16, 220, 96;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #0ec254;
  --ion-color-success-tint: #28e070;

  /** warning **/
  --ion-color-warning: #ffce00;
  --ion-color-warning-rgb: 255, 206, 0;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255, 255, 255;
  --ion-color-warning-shade: #e0b500;
  --ion-color-warning-tint: #ffd31a;

  /** danger **/
  --ion-color-danger: #f04141;
  --ion-color-danger-rgb: 245, 61, 61;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #d33939;
  --ion-color-danger-tint: #f25454;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 34, 34;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 244, 244;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  /** taost class **/
  --ion-color-toastsuccess: #43AC6A;
  --ion-color-toastsuccess-rgb: 67, 172, 106;
  --ion-color-toastsuccess-contrast: #ffffff;
  --ion-color-medium-toastsuccess-rgb: 255, 255, 255;
  --ion-color-toastsuccess-shade: #d7d8da;
  --ion-color-toastsuccess-tint: #f5f6f9;

  --ion-color-toastinfo: #008CBA;
  --ion-color-toastinfo-rgb: 0, 140, 186;
  --ion-color-toastinfo-contrast: #ffffff;
  --ion-color-medium-toastinfo-rgb: 255, 255, 255;
  --ion-color-toastinfo-shade: #d7d8da;
  --ion-color-toastinfo-tint: #f5f6f9;

  --ion-color-toastwarning: #f08a24;
  --ion-color-toastwarning-rgb: 240, 138, 36;
  --ion-color-toastwarning-contrast: #ffffff;
  --ion-color-medium-toastwarning-rgb: 255, 255, 255;
  --ion-color-toastwarning-shade: #d7d8da;
  --ion-color-toastwarning-tint: #f5f6f9;

  --ion-color-toasterror: #f04124;
  --ion-color-toasterror-rgb: 240, 65, 36;
  --ion-color-toasterror-contrast: #ffffff;
  --ion-color-medium-toasterror-rgb: 255, 255, 255;
  --ion-color-toasterror-shade: #d7d8da;
  --ion-color-toasterror-tint: #f5f6f9;
  
}

.ion-color-toastsuccess {
  --ion-color-base: var(--ion-color-toastsuccess);
  --ion-color-base-rgb: var(--ion-color-toastsuccess-rgb);
  --ion-color-contrast: var(--ion-color-toastsuccess-contrast);
  --ion-color-contrast-rgb: var(--ion-color-toastsuccess-contrast-rgb);
  --ion-color-shade: var(--ion-color-toastsuccess-shade);
  --ion-color-tint: var(--ion-color-toastsuccess-tint);
}
.ion-color-toastinfo {
  --ion-color-base: var(--ion-color-toastinfo);
  --ion-color-base-rgb: var(--ion-color-toastinfo-rgb);
  --ion-color-contrast: var(--ion-color-toastinfo-contrast);
  --ion-color-contrast-rgb: var(--ion-color-toastinfo-contrast-rgb);
  --ion-color-shade: var(--ion-color-toastinfo-shade);
  --ion-color-tint: var(--ion-color-toastinfo-tint);
}
.ion-color-toastwarning {
  --ion-color-base: var(--ion-color-toastwarning);
  --ion-color-base-rgb: var(--ion-color-toastwarning-rgb);
  --ion-color-contrast: var(--ion-color-toastwarning-contrast);
  --ion-color-contrast-rgb: var(--ion-color-toastwarning-contrast-rgb);
  --ion-color-shade: var(--ion-color-toastwarning-shade);
  --ion-color-tint: var(--ion-color-toastwarning-tint);
}
.ion-color-toasterror {
  --ion-color-base: var(--ion-color-toasterror);
  --ion-color-base-rgb: var(--ion-color-toasterror-rgb);
  --ion-color-contrast: var(--ion-color-toasterror-contrast);
  --ion-color-contrast-rgb: var(--ion-color-toasterror-contrast-rgb);
  --ion-color-shade: var(--ion-color-toasterror-shade);
  --ion-color-tint: var(--ion-color-toasterror-tint);
}

html, body { height: 100%; }
body { margin: 0; font-family: Poppins, "Helvetica Neue", sans-serif; }

ion-toolbar[color=primary-tint]{
  color: #FFFFFF;
  background: var(--ion-color-primary-tint);
}

