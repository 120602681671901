@mixin buttons($theme) {
  $primary: map-get($theme, primary);
  $warn: map-get($theme, warn);
  $accent: map-get($theme, accent);

/*   md-icon {
    font-family: 'Material Icons' !important;
  } */

  button {
    &.mat-raised-button {
      &.small{
        font-size: 12px;
        padding: 0 7px;
        line-height: 26px;
        mat-icon{
          height: 20px;
          width: 20px;
          line-height: 20px;
          font-size: 18px;
        }
      }
    }
    &.mat-rounded-button {
      height: 58px;
      display: block;
      margin: 30px auto 10px auto;
      border-radius: 28px;
      padding: 0 32px;
      font-size: 16px;
      &.small{
        height: 38px;
        font-size: 12px;
        padding: 0 20px;
      }
    }
    &.toolbar-rounded-button {
      height: 40px;
      display: block;
      margin: 0 auto;
      border-radius: 20px;
      padding: 0 20px;
      font-size: 16px;

      &.small {
        height: 38px;
        font-size: 12px;
        padding: 0 20px;
      }
    }

    &.mat-full-width-button {
      width: 100%;
      display: block;
      margin: 0 auto;
      border-radius: 0px;
      padding: 10px;
      font-size: 16px;
    }

    &.mat-icon-button{
      &[disabled] {
        &.disabledWhite.disabledWhite{
          color: rgba(255,255,255,0.3);
        } 
      }
      &.white.white{
        background: white;
        .mat-ripple-element {
          background-color: black;
        }
      }
    }
    &.mat-stroked-button{
      &.whiteBorder.whiteBorder{
        border-color: white;
      }
      &.smallPadding.smallPadding{
        padding: 0 5px;
      }
      &.smallRadius.smallRadius{
        border-radius: 2px;
      }
    }
    &.datesSelectorButton{
      height: 36px;
      .mat-button-wrapper{
        display: flex;
        align-items: center;
        .fromTo, .period{
          display: flex;
          margin-left: 5px;
          div{
            line-height: normal;
            margin-right: 6px;
            text-align: left;
            div:first-of-type{
              font-size: 10px;
              font-weight: 100;
            }
            div:last-of-type{
              font-size: 12px;
              font-weight: 600;
            }
          }
        }
      }
    }

    /**
    * COLORS 
    */
    &.primary {
      background-color: mat-color($primary);
      color: white;
    }

    &.warn {
      background-color: mat-color($warn);
      color: white;
    }

    &.accent {
      background-color: mat-color($accent);
      color: white;
    }
  }
}